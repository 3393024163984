import React from 'react';
import { patientDetails } from '../../data/data';

const ProjectStatus = () => {
  // Function to return the width based on the patient's status
  const getStatusWidth = (status) => {
    switch (status) {
      case 'Pending':
        return 0; // Width for 'Pending' is 0%
      case 'Scheduled':
        return 50; // Width for 'Scheduled' is 50%
      case 'Discharged':
        return 100; // Width for 'Discharged' is 100%
      case 'Transferred':
        return 50; // Width for 'Transferred' is 50%
      case 'Admitted':
        return Math.floor(Math.random() * (70 - 10 + 1)) + 10; // Random width between 10 and 70 for 'Admitted'
      default:
        return 0; // Default width if status is unknown
    }
  };

  return (
    <div className="w-full grid-margin stretch-card">
      <div className="card bg-white  p-4 shadow-md rounded-md">
        <div className="card-body">
          <h4 className="card-title text-xl font-semibold">Patient Status</h4>
          <div className="table-responsive mt-4">
            {/* Wrapper to make table scrollable on smaller screens */}
            <div className="overflow-x-auto">
              <table className="table table-bordered table-striped w-full">
                <thead className="bg-gray-100">
                  <tr>
                    <th className="p-2 text-left">PId</th>
                    <th className="p-2 text-left">Name</th>
                    <th className="p-2 text-left">Admission Date</th>
                    <th className="p-2 text-left">Doctor</th>
                    <th className="p-2 text-left">Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(patientDetails).slice(15, 20).map(([id, item]) => (
                    <tr key={id} className="text-center">
                      <td className="p-2 text-left">{id}</td>
                      <td className="p-2 text-left">{item.name}</td>
                      <td className="p-2 text-left">{item.date}</td>
                      <td className="p-2 text-left">{item.doctor}</td>
                      <td className="p-2 text-left">
                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                          <div
                            className={`h-2.5 rounded-full transition-all duration-300 ease-in-out
                              ${item.status === 'Discharged' ? 'bg-green-500' :
                               item.status === 'Scheduled' ? 'bg-blue-500' :
                               item.status === 'Pending' ? 'bg-yellow-500' :
                               item.status === 'Transferred' ? 'bg-orange-500' :
                               item.status === 'Admitted' ? 'bg-red-500' : 'bg-gray-500'}
                            `}
                            style={{ width: `${getStatusWidth(item.status)}%` }}
                          ></div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectStatus;
