import React from 'react'

import Navbar from './Navbar'
import Hero from './Hero'
import About from './About'
import WeDo from './WeDo'
import Team from './Team'
import Footer from './Footer'
import Contact from './Contact'
import Campaigns from './Campaigns'
export default function Home() {
  

  return (
    <div className=''>
    <Navbar className="fixed top-0 left-0 w-full z-50" page={'home'} />
    <Hero/>
    <About/>
    <WeDo/>
    <Team/>
    <Campaigns/>
    <Contact/>
    <Footer/>
  </div>
  )
}
