export const appointmentDates = {
    1: { day: 1, patientIds: ['P01','P02'] }, // 1 surgery
    2: { day: 2, patientIds: [] }, // 0 surgery
    3: { day: 3, patientIds: [] }, // 0 surgeries
    4: { day: 4, patientIds: ['P03', 'P04'] }, // 1 surgery
    5: { day: 5, patientIds: ['P05'] }, // 1 surgery
    7: { day: 6, patientIds: ['P06'] }, // 1 surgeries
    6: { day: 7, patientIds: ['P07', 'P08'] }, // 2 surgeries
    8: { day: 8, patientIds: ['P09','P10'] }, // 1 surgery
    9: { day: 9, patientIds: [] }, // 0 surgery
    10: { day: 10, patientIds: [] }, // 0 surgeries
    11: { day: 11, patientIds: ['P11', 'P12'] }, // 2 surgeries
    12: { day: 12, patientIds: ['P13'] }, // 1 surgery
    14: { day: 13, patientIds: [] }, // 0 surgeries
    13: { day: 14, patientIds: ['P14', 'P15'] }, // 2 surgeries
    15: { day: 15, patientIds: ['P16','P17'] }, // 2 surgery
    16: { day: 16, patientIds: [] }, // 0 surgeries
    17: { day: 17, patientIds: [] }, // 0 surgeries
    18: { day: 18, patientIds: ['P18','P19'] }, // 2 surgery
    19: { day: 19, patientIds: ['P20'] }, // 1 surgery
    21: { day: 20, patientIds: [] }, // 0 surgeries
    20: { day: 21, patientIds: ['P21', 'P22'] }, // 2 surgeries
    22: { day: 22, patientIds: ['P23','P24'] }, // 2 surgery
    23: { day: 23, patientIds: [ ] }, // 0 surgeries
    24: { day: 24, patientIds: [] }, // 0 surgeries
    25: { day: 25, patientIds: ['P25','P26'] }, // 2 surgery
    26: { day: 26, patientIds: ['P27'] }, // 1 surgery
    27: { day: 27, patientIds: [] }, // 0 surgeries
    28: { day: 28, patientIds: ['P28', 'P29'] }, // 2 surgeries
    29: { day: 29, patientIds: [] }, // 0 surgery
    30: { day: 30, patientIds: [] }, // 0 surgeries
};


export const patientDetails = {
    P01: { 
        image: <img src={require('../images/male/1.webp')} className='w-10 rounded-full' />, 
        name: 'John Doe', 
        surgery: 'Appendectomy', 
        time: '10:00-10:30', 
        doctor: 'Dr. Smith', 
        date: '01/11/2024', 
        status: getRandomStatus()
    },
    P02: { 
        image: <img src={require('../images/female/2.webp')} className='w-10 rounded-full' />, 
        name: 'Jane Smith', 
        surgery: 'Cholecystectomy', 
        time: '10:30-11:00', 
        doctor: 'Dr. Jones', 
        date: '01/11/2024', 
        status: getRandomStatus()
    },
    P03: { 
        image: <img src={require('../images/male/11.webp')} className='w-10 rounded-full' />, 
        name: 'Sam Green', 
        surgery: 'Hernia Repair', 
        time: '11:00-11:30', 
        doctor: 'Dr. Lee', 
        date: '04/11/2024', 
        status: getRandomStatus()
    },
    P04: { 
        image: <img src={require('../images/male/2.webp')} className='w-10 rounded-full' />, 
        name: 'Chris Brown', 
        surgery: 'Knee Replacement', 
        time: '11:30-12:00', 
        doctor: 'Dr. Taylor', 
        date: '04/11/2024', 
        status: getRandomStatus()
    },
    P05: { 
        image: <img src={require('../images/female/3.webp')} className='w-10 rounded-full' />, 
        name: 'Lisa White', 
        surgery: 'Hip Replacement', 
        time: '12:00-12:30', 
        doctor: 'Dr. Kim', 
        date: '05/11/2024', 
        status: getRandomStatus()
    },
    P06: { 
        image: <img src={require('../images/male/3.webp')} className='w-10 rounded-full' />, 
        name: 'Mike Blue', 
        surgery: 'Carpal Tunnel Release', 
        time: '12:30-1:00', 
        doctor: 'Dr. Wilson', 
        date: '06/11/2024', 
        status: getRandomStatus()
    },
    P07: { 
        image: <img src={require('../images/female/4.webp')} className='w-10 rounded-full' />, 
        name: 'Anna Black', 
        surgery: 'Bariatric Surgery', 
        time: '1:00-1:30', 
        doctor: 'Dr. Davis', 
        date: '07/11/2024', 
        status: getRandomStatus()
    },
    P08: { 
        image: <img src={require('../images/male/4.webp')} className='w-10 rounded-full' />, 
        name: 'Tom Red', 
        surgery: 'Gallbladder Surgery', 
        time: '1:30-2:00', 
        doctor: 'Dr. Clark', 
        date: '07/11/2024', 
        status: getRandomStatus()
    },
    P09: { 
        image: <img src={require('../images/female/5.webp')} className='w-10 rounded-full' />, 
        name: 'Lucy Grey', 
        surgery: 'Cataract Surgery', 
        time: '2:00-2:30', 
        doctor: 'Dr. Lewis', 
        date: '08/11/2024', 
        status: getRandomStatus()
    },
    P10: { 
        image: <img src={require('../images/male/5.webp')} className='w-10 rounded-full' />, 
        name: 'Paul Yellow', 
        surgery: 'Spinal Fusion', 
        time: '2:30-3:00', 
        doctor: 'Dr. Walker', 
        date: '08/11/2024', 
        status: getRandomStatus()
    },
    P11: { 
        image: <img src={require('../images/female/6.webp')} className='w-10 rounded-full' />, 
        name: 'Sophia Pink', 
        surgery: 'Thyroid Surgery', 
        time: '3:00-3:30', 
        doctor: 'Dr. Hall', 
        date: '11/11/2024', 
        status: getRandomStatus()
    },
    P12: { 
        image: <img src={require('../images/male/6.webp')} className='w-10 rounded-full' />, 
        name: 'James Orange', 
        surgery: 'Prostate Surgery', 
        time: '3:30-4:00', 
        doctor: 'Dr. Allen', 
        date: '11/11/2024', 
        status: getRandomStatus()
    },
    P13: { 
        image: <img src={require('../images/female/7.webp')} className='w-10 rounded-full' />, 
        name: 'Eva Violet', 
        surgery: 'Dental Surgery', 
        time: '4:00-4:30', 
        doctor: 'Dr. Young', 
        date: '12/11/2024', 
        status: getRandomStatus()
    },
    P14: { 
        image: <img src={require('../images/male/7.webp')} className='w-10 rounded-full' />, 
        name: 'Oliver Teal', 
        surgery: 'Sinus Surgery', 
        time: '4:30-5:00', 
        doctor: 'Dr. King', 
        date: '14/11/2024', 
        status: getRandomStatus()
    },
    P15: { 
        image: <img src={require('../images/female/8.webp')} className='w-10 rounded-full' />, 
        name: 'Mia Cyan', 
        surgery: 'Laparoscopy', 
        time: '5:00-5:30', 
        doctor: 'Dr. Wright', 
        date: '14/11/2024', 
        status: getRandomStatus()
    },
    P16: { 
        image: <img src={require('../images/male/8.webp')} className='w-10 rounded-full' />, 
        name: 'Noah Grey', 
        surgery: 'Hernia Repair', 
        time: '10:00-10:30', 
        doctor: 'Dr. Smith', 
        date: '15/11/2024', 
        status: getRandomStatus()
    },
    P17: { 
        image: <img src={require('../images/male/9.webp')} className='w-10 rounded-full' />, 
        name: 'Liam Black', 
        surgery: 'Gallbladder Surgery', 
        time: '10:30-11:00', 
        doctor: 'Dr. Jones', 
        date: '15/11/2024', 
        status: getRandomStatus()
    },
    P18: { 
        image: <img src={require('../images/female/9.webp')} className='w-10 rounded-full' />, 
        name: 'Emma White', 
        surgery: 'Appendectomy', 
        time: '11:00-11:30', 
        doctor: 'Dr. Lee', 
        date: '18/11/2024', 
        status: getRandomStatus()
    },
    P19: { 
        image: <img src={require('../images/female/10.webp')} className='w-10 rounded-full' />, 
        name: 'Olivia Brown', 
        surgery: 'Hip Replacement', 
        time: '11:30-12:00', 
        doctor: 'Dr. Taylor', 
        date: '18/11/2024', 
        status: getRandomStatus()
    },
    P20: { 
        image: <img src={require('../images/female/11.webp')} className='w-10 rounded-full' />, 
        name: 'Ava Green', 
        surgery: 'Knee Replacement', 
        time: '12:00-12:30', 
        doctor: 'Dr. Kim', 
        date: '19/11/2024', 
        status: getRandomStatus()
    },
    P21: { 
        image: <img src={require('../images/female/12.webp')} className='w-10 rounded-full' />, 
        name: 'Isabella Red', 
        surgery: 'Thyroid Surgery', 
        time: '12:30-1:00', 
        doctor: 'Dr. Wilson', 
        date: '21/11/2024', 
        status: getRandomStatus()
    },
    P22: { 
        image: <img src={require('../images/female/13.webp')} className='w-10 rounded-full' />, 
        name: 'Sophia Yellow', 
        surgery: 'Bariatric Surgery', 
        time: '1:00-1:30', 
        doctor: 'Dr. Davis', 
        date: '21/11/2024', 
        status: getRandomStatus()
    },
    P23: { 
        image: <img src={require('../images/female/14.webp')} className='w-10 rounded-full' />, 
        name: 'Mia Blue', 
        surgery: 'Cataract Surgery', 
        time: '1:30-2:00', 
        doctor: 'Dr. Clark', 
        date: '22/11/2024', 
        status: getRandomStatus()
    },
    P24: { 
        image: <img src={require('../images/female/15.webp')} className='w-10 rounded-full' />, 
        name: 'Zoe Pink', 
        surgery: 'Prostate Surgery', 
        time: '2:00-2:30', 
        doctor: 'Dr. Lewis', 
        date: '22/11/2024', 
        status: getRandomStatus()
    },
    P25: { 
        image: <img src={require('../images/female/16.webp')} className='w-10 rounded-full' />, 
        name: 'Lily Orange', 
        surgery: 'Spinal Fusion', 
        time: '2:30-3:00', 
        doctor: 'Dr. Walker', 
        date: '25/11/2024', 
        status: getRandomStatus()
    },
    P26: { 
        image: <img src={require('../images/female/17.webp')} className='w-10 rounded-full' />, 
        name: 'Ella Violet', 
        surgery: 'Dental Surgery', 
        time: '3:00-3:30', 
        doctor: 'Dr. Hall', 
        date: '25/11/2024', 
        status: getRandomStatus()
    },
    P27: { 
        image: <img src={require('../images/female/18.webp')} className='w-10 rounded-full' />, 
        name: 'Grace Teal', 
        surgery: 'Sinus Surgery', 
        time: '3:30-4:00', 
        doctor: 'Dr. Allen', 
        date: '26/11/2024', 
        status: getRandomStatus()
    },
    P28: { 
        image: <img src={require('../images/female/1.jpg')} className='w-10 rounded-full' />, 
        name: 'Chloe Grey', 
        surgery: 'Laparoscopy', 
        time: '4:00-4:30', 
        doctor: 'Dr. Young', 
        date: '28/11/2024', 
        status: getRandomStatus()
    },
    P29: { 
        image: <img src={require('../images/male/12.webp')} className='w-10 rounded-full' />, 
        name: 'Aubrey Black', 
        surgery: 'Hernia Repair', 
        time: '4:30-5:00', 
        doctor: 'Dr. King', 
        date: '28/11/2024', 
        status: getRandomStatus()
    }
};

// Utility function to return a random status
function getRandomStatus() {
    const statuses = ['Pending', 'Scheduled', 'Discharged', 'Transferred', 'Admitted'];
    return statuses[Math.floor(Math.random() * statuses.length)];
}




export const labTests = [
    { testId: 'T001', patientName: 'John Doe', testType: 'Complete Blood Count', orderedBy: 'Dr. Smith', date: '01/10/2024' },
    { testId: 'T002', patientName: 'Jane Smith', testType: 'Urinalysis', orderedBy: 'Dr. Jones', date: '02/10/2024' },
    { testId: 'T003', patientName: 'Sam Green', testType: 'Lipid Panel', orderedBy: 'Dr. Lee', date: '03/10/2024' },
    { testId: 'T004', patientName: 'Chris Brown', testType: 'X-Ray', orderedBy: 'Dr. Taylor', date: '04/10/2024' },
    { testId: 'T005', patientName: 'Lisa White', testType: 'MRI Scan', orderedBy: 'Dr. Kim', date: '05/10/2024' },
    { testId: 'T006', patientName: 'Mike Blue', testType: 'CT Scan', orderedBy: 'Dr. Wilson', date: '06/10/2024' },
    { testId: 'T007', patientName: 'Anna Black', testType: 'Blood Sugar Test', orderedBy: 'Dr. Davis', date: '07/10/2024' },
    { testId: 'T008', patientName: 'Tom Red', testType: 'Hepatitis Panel', orderedBy: 'Dr. Clark', date: '08/10/2024' },
    { testId: 'T009', patientName: 'Lucy Grey', testType: 'Thyroid Function Test', orderedBy: 'Dr. Lewis', date: '09/10/2024' },
    { testId: 'T010', patientName: 'Paul Yellow', testType: 'Electrocardiogram (ECG)', orderedBy: 'Dr. Walker', date: '10/10/2024' },
    { testId: 'T011', patientName: 'Sophia Pink', testType: 'Vitamin D Level', orderedBy: 'Dr. Hall', date: '11/10/2024' },
    { testId: 'T012', patientName: 'James Orange', testType: 'Prostate-Specific Antigen (PSA)', orderedBy: 'Dr. Allen', date: '12/10/2024' },
    { testId: 'T013', patientName: 'Eva Violet', testType: 'Complete Metabolic Panel', orderedBy: 'Dr. Young', date: '13/10/2024' },
    { testId: 'T014', patientName: 'Oliver Teal', testType: 'Coagulation Panel', orderedBy: 'Dr. King', date: '14/10/2024' },
    { testId: 'T015', patientName: 'Mia Cyan', testType: 'Pregnancy Test', orderedBy: 'Dr. Wright', date: '15/10/2024' },
    { testId: 'T016', patientName: 'Noah Grey', testType: 'Allergy Testing', orderedBy: 'Dr. Smith', date: '16/10/2024' },
    { testId: 'T017', patientName: 'Liam Black', testType: 'Bone Density Test', orderedBy: 'Dr. Jones', date: '17/10/2024' },
    { testId: 'T018', patientName: 'Emma White', testType: 'Genetic Testing', orderedBy: 'Dr. Lee', date: '18/10/2024' },
    { testId: 'T019', patientName: 'Olivia Brown', testType: 'Bacterial Culture', orderedBy: 'Dr. Taylor', date: '19/10/2024' },
    { testId: 'T020', patientName: 'Ava Green', testType: 'Urine Culture', orderedBy: 'Dr. Kim', date: '20/10/2024' },
    { testId: 'T021', patientName: 'Isabella Red', testType: 'Liver Function Test', orderedBy: 'Dr. Wilson', date: '21/10/2024' },
    { testId: 'T022', patientName: 'Sophia Yellow', testType: 'Kidney Function Test', orderedBy: 'Dr. Davis', date: '22/10/2024' },
    { testId: 'T023', patientName: 'Mia Blue', testType: 'Chest X-Ray', orderedBy: 'Dr. Clark', date: '23/10/2024' },
    { testId: 'T024', patientName: 'Zoe Pink', testType: 'Cardiac Stress Test', orderedBy: 'Dr. Lewis', date: '24/10/2024' },
    { testId: 'T025', patientName: 'Lily Orange', testType: 'Skin Test for TB', orderedBy: 'Dr. Walker', date: '25/10/2024' },
    { testId: 'T026', patientName: 'Ella Violet', testType: 'Nutritional Assessment', orderedBy: 'Dr. Hall', date: '26/10/2024' },
    { testId: 'T027', patientName: 'Grace Teal', testType: 'Rapid COVID-19 Test', orderedBy: 'Dr. Allen', date: '27/10/2024' },
    { testId: 'T028', patientName: 'Chloe Grey', testType: 'Fecal Occult Blood Test', orderedBy: 'Dr. Young', date: '28/10/2024' },
    { testId: 'T029', patientName: 'Aubrey Black', testType: 'Echocardiogram', orderedBy: 'Dr. King', date: '29/10/2024' },
    { testId: 'T030', patientName: 'Unknown Patient', testType: 'Random Test', orderedBy: 'Unknown Doctor', date: '30/10/2024' }
];
  
  
  

export const surgeryData = [
    { department: 'Cardiology', surgeriesPerformed: 250, avgDuration: 3.5, successRate: 95 },
    { department: 'Orthopedics', surgeriesPerformed: 300, avgDuration: 2.0, successRate: 90 },
    { department: 'Neurology', surgeriesPerformed: 180, avgDuration: 4.0, successRate: 92 },
    { department: 'Pediatrics', surgeriesPerformed: 120, avgDuration: 1.5, successRate: 97 },
    { department: 'General Surgery', surgeriesPerformed: 400, avgDuration: 3.0, successRate: 94 },
    { department: 'Gynecology', surgeriesPerformed: 220, avgDuration: 2.5, successRate: 93 },
    { department: 'Urology', surgeriesPerformed: 150, avgDuration: 2.2, successRate: 89 },
];