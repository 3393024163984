import React from 'react'
import { PhoneIcon, EnvelopeIcon, MapIcon } from '@heroicons/react/24/outline';
import { FaFacebook,FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

export default function Contact() {
  return (
    <section className="p-6 md:p-14 bg-gray-100 contact">
        <div className="max-w-6xl mx-auto ">
            <h2 className="text-3xl font-bold text-center ">Contact Our Friendly Team</h2>
            <p className='text-sm  text-center mb-8'>Reach out to us for support and guidance anytime</p>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-10 lg:gap-20">
                <div className="p-6 rounded-lg shadow-lg space-y-6" style={{ backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
                    <h3 className="text-xl font-semibold mb-4">Contact Information</h3>
                    <div className="flex items-center mb-2 gap-4">
                        <div className="hidden sm:flex items-center justify-center w-10 h-10 rounded-full bg-teal-600 text-white hover:bg-teal-500 transition duration-200">

                        <PhoneIcon className=" w-5 h-5  " />
                        </div>
                        <span className='text-sm sm:text-lg'><strong>Phone:</strong> (123) 456-7890</span>
                    </div>
                    <div className="flex items-center mb-2 gap-4">
                        <div className="hidden sm:flex items-center justify-center w-10 h-10 rounded-full bg-teal-600 text-white hover:bg-teal-500 transition duration-200">

                        <EnvelopeIcon className="w-5 h-5 " />
                        </div>
                        <span className='text-sm sm:text-lg'><strong>Email:</strong> contact@hospital.com</span>
                    </div>
                    <div className="flex items-center mb-2 gap-4">
                        <div className="hidden sm:flex items-center justify-center w-10 h-10 rounded-full bg-teal-600 text-white hover:bg-teal-500 transition duration-200">

                        <MapIcon className="w-5 h-5 " />
                        </div>
                        <span className='text-sm sm:text-lg'><strong>Address:</strong> 123 Hospital Rd, City, State, ZIP</span>
                    </div>
                
                    <h4 className="mt-6 mb-2 font-semibold">Follow Us</h4>
                
                    <div className="flex space-x-4">
                        <div className="flex items-center justify-center w-10 h-10 rounded-full">
                        <a href="#" className="flex items-center justify-center">
                        <FaFacebook style={{ color: '#1877F2' }} className="w-5 h-5 " />
                        </a>
                        </div>
                        <div className="flex items-center justify-center w-10 h-10 ">
                        <a href="#" className="flex items-center justify-center">
                        <FaTwitter style={{ color: '#1DA1F2' }} className="w-5 h-5 " />
                        </a>
                        </div>
                        <div className="flex items-center justify-center w-10 h-10 ">
                        <a href="#" className="flex items-center justify-center">
                        <FaLinkedin style={{ color: '#0077B5' }} className="w-5 h-5 " />
                        </a>
                        </div>
                        <div className="flex items-center justify-center w-10 h-10 ">
                        <a href="#" className="flex items-center justify-center">
                        <FaInstagram style={{ color: '#C13584' }} className="w-5 h-5 " />
                        </a>
                        </div>
                    </div>
                </div>
                
                <div className=" p-6 rounded-lg shadow-lg" style={{ backgroundColor: 'rgba(255, 255, 255, 0.85)' }}>
                    <h3 className="text-xl font-semibold mb-4">Get in Touch</h3>
                    <form>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700" htmlFor="name">Name</label>
                        <input
                        type="text"
                        id="name"
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700" htmlFor="email">Email</label>
                        <input
                        type="email"
                        id="email"
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700" htmlFor="message">Message</label>
                        <textarea
                        id="message"
                        rows="4"
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        required
                        ></textarea>
                    </div>
                    <button
                        type="submit"
                        className="w-full bg-blue-600 text-white font-semibold py-2 rounded-md hover:bg-blue-500 transition duration-200"
                    >
                        Send Message
                    </button>
                    </form>
                </div>
          
            </div>
        </div>
    </section>
  )
}
