import React from 'react'
import { Link } from 'react-router-dom';
import { UserIcon, LockClosedIcon } from '@heroicons/react/24/solid'; 
import google from '../images/icons/google.png'
import email from '../images/icons/gmail.png'

import Footer from './Footer'
import Navbar1 from './Navbar1';

export default function Signin() {
  
  return (
    <div className="min-h-screen flex flex-col">
      <Navbar1 className=" top-0 left-0 w-full z-50" page={'signin'} />
      <div className="flex justify-center flex-grow">
        <div className="p-4 md:p-14 flex-grow  max-w-xl flex items-center justify-center ">
          <div className="w-full  bg-white p-4 md:p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-center mb-6">Signin with</h2>
            <div className="flex justify-center flex-wrap gap-4 mb-4">
              {/* Google Button */}
              <div className="flex flex-grow items-center justify-center space-x-2 cursor-pointer bg-gray-100 py-4 px-6 rounded-lg hover:bg-gray-200 transition">
                <img src={google} alt="" className='w-5 h-5' />
                <span>Google</span>
              </div>
              {/* Email Button */}
              <div className="flex flex-grow items-center justify-center space-x-2 cursor-pointer bg-gray-100 py-4 px-6 rounded-lg hover:bg-gray-200 transition">
                <img src={email} alt="" className='w-5 h-5' />
                <span>Email</span>
              </div>
            </div>
            <div className="flex items-center gap-2 text-center mb-4">
              <div className="w-full h-px bg-black"></div>
              <span className="text-black">or</span>
              <div className="w-full h-px bg-black"></div>
              <hr />
            </div>
            {/* Username Input */}
            <div className="mb-4">
            <label htmlFor="username" className="block text-sm font-medium text-gray-700">Username</label>
            <div className="mt-1 relative">
              <input
                id="username"
                type="text"
                placeholder="Enter your username"
                className="w-full pl-10 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              />
              <UserIcon className="w-5 h-5 absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
            </div>
          </div>
            {/* Password Input */}
            <div className="mb-6">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700">Password</label>
              <div className="mt-1 relative">
                <input
                  id="password"
                  type="password"
                  placeholder="Enter your password"
                  className="w-full px-10 py-3 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                />
                <LockClosedIcon className="w-5 h-5 absolute top-1/2 left-3 transform -translate-y-1/2 text-gray-400" />
              </div>
            </div>
            {/* Forgot Password Link */}
            <div className="text-right mb-4">
              <a href="#" className="text-sm text-blue-500 hover:underline">Forgot Password?</a>
            </div>
            {/* Signin Button */}
            <div className="w-full flex justify-center">

              <button className="p-4 bg-primary text-black hover:text-white py-2 rounded-md hover:bg-secondary">
                Signin
              </button>
            </div>
            {/* Sign up Link */}
            <div className="mt-4 text-center">
              <span className="flex gap-2 justify-center flex-wrap text-sm text-gray-600">
                <p>Don't have an account?</p>
                
                <Link to="/signup" className="text-blue-500">Signup</Link>
              </span>
            </div>
          </div>
        </div>

      </div>

      <Footer/>
    </div>
  )
}
