import React from 'react'
import { Link } from 'react-router-dom'
import { FaFacebook,FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';
export default function Footer() {
    return (
        <div className='mt-auto'>
        
            <footer className="bg-gray-800 text-white  p-8">
                <div className="container mx-auto ">
                    <div className="flex flex-col md:flex-row justify-around gap-4 md:gap-12 mb-6 md:mb-0">
                        <div className="basis-1/2 " >
                            <h3 className="text-xl font-bold mb-2">HopeMed</h3>
                            <p>Empowering individuals to learn and grow through accessible online education.</p>
                        </div>

                        <div className="basis-1/4 ">
                            <h3 className="text-xl font-bold mb-2">Contact Us</h3>
                            <div className="space-y-1">

                                <p>Email: support@hopemed.com</p>
                                <p>Phone: (123) 456-7890</p>
                            </div>
                        </div>

                        <div className="basis-1/4  items-center">
                            <h3 className="text-xl font-bold mb-2">Follow Us</h3>
                            <div className="flex space-x-6 ">                           
                                <a href="#" className="flex items-center justify-center">
                                <FaFacebook style={{ color: '#1877F2' }} className="w-5 h-5 " />
                                </a>
                                <a href="#" className="flex items-center justify-center">
                                <FaTwitter style={{ color: '#1DA1F2' }} className="w-5 h-5 " />
                                </a>
                                <a href="#" className="flex items-center justify-center">
                                <FaLinkedin style={{ color: '#0077B5' }} className="w-5 h-5 " />
                                </a>
                                <a href="#" className="flex items-center justify-center">
                                <FaInstagram style={{ color: '#C13584' }} className="w-5 h-5 " />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="text-center mt-8">
                        <div className="md:flex justify-center gap-4 mb-2">
                            <div className="flex justify-around mb-2 md:gap-4">
                                
                                <p>
                                    <Link to="/"  className=" hover:text-gray-200 transition duration-300">Privacy Policy</Link>
                                </p>
                                <p>
                                    <Link to="/"  className=" hover:text-gray-200 transition duration-300">Terms & Condition </Link>
                                </p>
                                
                                <div className="hidden md:block h-6 w-px bg-gray-300 " /></div>
                                <p>&copy; {new Date().getFullYear()} HopeMed. All rights reserved.</p>
                            </div>
                        </div>
                    </div>
            </footer>
        </div>
  )
}
