import React from 'react';

const StatsCard = ({ title, amount, status, bgColor, icon }) => {
  return (
    <div className={`bg-gradient-to-r p-6 text-white rounded-lg shadow-lg ${bgColor}`}>
      <div className="flex justify-between items-center">
        <div>
          <h4 className="text-lg font-semibold">{title}</h4>
          <h2 className="text-3xl font-bold my-2">{amount}</h2>
          <h6>{status}</h6>
        </div>
        <i className={`mdi ${icon} text-3xl`}></i>
      </div>
    </div>
  );
};

export default StatsCard;
