import React, { useState } from "react";
import dayjs from "dayjs"; 
import "tailwindcss/tailwind.css";

const SurgeryCalendar = () => {
  const [currentMonth] = useState(dayjs()); 

  const surgeryDates = [
    dayjs().date(1), dayjs().date(5), dayjs().date(7), dayjs().date(8),
    dayjs().date(11), dayjs().date(12), dayjs().date(15),dayjs().date(16), dayjs().date(19),
    dayjs().date(20), dayjs().date(21),dayjs().date(23), dayjs().date(25), dayjs().date(27),
    dayjs().date(28), dayjs().date(29)
  ];

  const [surgeries, setSurgeries] = useState(surgeryDates);

  
  const getDaysInMonth = (month) => {
    const startOfMonth = month.startOf("month");
    const endOfMonth = month.endOf("month");
    const startWeekday = startOfMonth.day(); // Get the weekday of the 1st
    const days = [];

    // Add empty days before the first day of the month (padding)
    for (let i = 0; i < startWeekday; i++) {
      days.push(null); // Padding for the previous month's days
    }

    // Add days of the current month
    for (let day = startOfMonth; day.isBefore(endOfMonth, "day"); day = day.add(1, "day")) {
      days.push(day); // Actual days of the current month
    }

    return days;
  };

  const isToday = (date) => {
    return date && date.isSame(dayjs(), 'day'); // Ensure date is not null before checking
  };

  const isSurgeryDay = (date) => {
    return date && surgeries.some(surgery => surgery.isSame(date, 'day')); // Ensure date is not null
  };

  const daysInMonth = getDaysInMonth(currentMonth);

  return (
    <div className="max-w-2xl pt-8 p-4 bg-white shadow-md rounded-md">

      <div className="flex justify-between items-center mb-8">
        {/* Left button, no action */}
        <button className="text-xl font-semibold" disabled>
          &lt;
        </button>
        <h2 className="text-xl font-semibold">{currentMonth.format("MMMM YYYY")}</h2>
        {/* Right button, no action */}
        <button className="text-xl font-semibold" disabled>
          &gt;
        </button>
      </div>

      {/* Calendar Grid */}
      <div className="grid grid-cols-7 gap-2">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
          <div key={day} className="text-center font-semibold mb-4">{day}</div>
        ))}

        {daysInMonth.map((date, index) => (
          <div
            key={index}
            className={`text-center p-2 rounded-lg ${!date ? "bg-transparent" : ""}
              ${isToday(date) ? "bg-blue-500 text-white" : ""}
              ${isSurgeryDay(date) ? "bg-red-500 text-white" : ""}
              ${date && !isToday(date) && !isSurgeryDay(date) ? "hover:bg-gray-200" : ""}`}
          >
            {date ? date.date() : ''}
          </div>
        ))}
      </div>

      {/* Color Legend */}
      <div className="mt-6 flex gap-2 flex-wrap">
        <div className="flex items-center">
          <div className="w-4 h-4 bg-blue-500 mr-2"></div>
          <span>Today's Date</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-red-500 mr-2"></div>
          <span>Surgery Scheduled</span>
        </div>
        <div className="flex items-center">
          <div className="w-4 h-4 bg-gray-200 mr-2"></div>
          <span>Available Date (No Surgery)</span>
        </div>
      </div>
    </div>
  );
};

export default SurgeryCalendar;
