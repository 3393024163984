
import React, {useState} from 'react'
import { Link,useNavigate } from 'react-router-dom'

import { ChevronDownIcon,UserGroupIcon,HomeIcon,BellIcon,ArrowRightOnRectangleIcon ,UserIcon ,ChartBarIcon, CalendarIcon, CogIcon ,BeakerIcon } from '@heroicons/react/24/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd,  faUserTie, faBroom } from '@fortawesome/free-solid-svg-icons';
import Nurse from '../../images/icons/nurse black.png'
import Patient from '../../images/icons/patient black.png'
import Dashboard from './Dashboard';
import Sidebar from './Sidebar'

export default function Admin() {
    const navigate=useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [isUiBasicOpen, setIsUiBasicOpen] = useState(false);
    const [notsOpen, setNotsOpen] = useState(false);

    const nots = [
        { id: 1, heading: 'New Patient',text: 'John Doe', indication: 'Reminder', notcolor: 'text-red-400'},
        { id: 2, heading: 'New Doctor',text: 'Dr. Smith', indication: 'Reminder', notcolor: 'text-blue-400'},
        { id: 3, heading: 'Critical Stock Alert',text: 'Oxygen Tanks', indication: 'Low Stock', notcolor: 'text-yellow-400'},
        { id: 4, heading: 'New Patient',text: 'Joy Kean', indication: 'Surgery', notcolor: 'text-red-400'},
    ]
    const toggleCollapse = (setStateFn) => {
        setStateFn(prevState => !prevState);
      };

    const logout = () => {
        navigate('/');
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };
    const toggleNots = () => {
        setNotsOpen(!notsOpen);
        setIsMenuOpen(false);
    };

    

    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
        setNotsOpen(false);
    };
  return (
    <div>
            {/* Navbar */}
            
            <header className="bg-white shadow-lg p-4 flex items-center justify-between">
                <div className="logo heading font-bold text-xl md:text-3xl text-teal-500">HopeMed</div>
                <div className="flex md:gap-4 items-center">
                    <div className="lg:hidden">
                        <button onClick={toggleMenu} className="text-lg">
                            ☰
                        </button>
                    </div>
                    <div className="flex items-center">
                        
                        <button onClick={toggleNots} className="flex items-center ml-4">
                            <div className="nav-profile-image relative">
                                <BellIcon className="h-5 md:h-8 w-5 md:w-8"/>
                                <span className=" absolute top-0 right-0 w-4 h-4 bg-teal-500 animate-ping border-2 border-white rounded-full">
                               
                                </span>
                            </div>
                            
                            
                        </button>
                        {notsOpen && (
                            <div className="absolute right-2 top-16 w-64  bg-white shadow-lg rounded-md p-4">
                            
                            <h4 className="text-lg font-semibold text-gray-700">Notifications</h4>
        
                            <ul className="mt-2 space-y-3">
                                {/* Patient Admission */}
                                {nots.map((item)=>{
                                    return(
                                        <li className="text-sm text-gray-600 flex justify-between" key={item.id}>
                                            <div className="">
                                                <h1 className='font-semibold '>{item.heading}</h1>
                                                <p>{item.text}</p>
                                            </div>
                                            <div className="flex items-end">
                                                <span className={`text-xs ml-auto ${item.notcolor}`}>{item.indication}</span>

                                            </div>
                                        </li>

                                    )
                                })}
                                
                                
                            </ul>
                            </div>
                        )}
                        <button onClick={toggleDropdown} className="hidden md:flex items-center ml-4">
                            <div className="nav-profile-image relative">
                                <img src={require('../../images/male/face1.jpg')} alt="profile" className="w-10 h-10 rounded-full" />
                                <span className="login-status online absolute bottom-0 right-0 w-3 h-3 bg-green-500 border-2 border-white rounded-full"></span>
                                
                            </div>
                            
                            <div className="ml-2 text-right">
                            <div>Sandy</div>
                            <div className="text-sm text-gray-500">Admin</div>
                            </div>
                        </button>
                        {dropdownOpen && (
                            <div className="absolute right-0 mt-28 w-48 bg-white shadow-lg rounded-md">
                            <button onClick={logout} className="block px-4 py-2 text-left w-full hover:bg-gray-200">
                                Logout
                            </button>
                            </div>
                        )}
                    </div>
                </div>
            </header>
            <ul className={`bg-white shadow-lg text-black p-4 flex flex-col   items-start lg:hidden  ${isMenuOpen ? 'block' : 'hidden'} `}>
                <li className="px-4 py-2 ">
                    <Link to="" className='flex items-center'>
                    <HomeIcon className="h-6 w-6  menu-icon " />
                    <span className="menu-title ml-2">Dashboard</span>
                    </Link>
                </li>
                <li className="px-4 py-2 ">
                    <Link to="" className='flex items-center'>
                    <ChartBarIcon className="w-5 h-5 menu-icon mr-2" />
                    <span className="menu-title">Analytics</span>
                    </Link>
                </li>
                <li className="px-4 py-2 ">
                    <Link to="" className='flex items-center'>
                    <BeakerIcon className="w-5 h-5 menu-icon mr-2" />
                    <span className="menu-title">Lab Reports</span>
                    </Link>
                </li>
                <li className="px-4 py-2 ">
                    <Link to="" className='flex items-center'>
                    <img src={Patient} alt="" className='w-5 menu-icon mr-2 ' />
                    <span className="menu-title">Patients</span>
                    </Link>
                </li>
                
                <li className="px-4 py-2 ">
                    <Link to="" className='flex items-center' 
                        onClick={() => toggleCollapse(setIsUiBasicOpen)}>
                        <UserGroupIcon className="w-5 h-5 menu-icon mr-2" />
                        <span className="menu-title">Staffs</span>
                        <ChevronDownIcon className={`w-5 h-5 ml-2 ${isUiBasicOpen ? 'transform rotate-180' : ''}`} />
                    </Link>
                </li>
                {isUiBasicOpen && (
                    <ul className="nav flex-col m-4 ml-8 space-y-2">
                    
                    <li className="nav-item">
                        <a className="nav-link" href="">
                        <FontAwesomeIcon icon={faUserMd} className="" />
                        <span className="ml-2">Doctor</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link flex" href="">
                        <img src={Nurse} alt="" className='w-4' />
                        <span className="ml-2">Nurse</span>
                        </a>
                    </li>
                    
                    <li className="nav-item">
                        <a className="nav-link" href="">
                        <FontAwesomeIcon icon={faUserTie} className="" />
                        <span className="ml-2">Manager</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="">
                        <FontAwesomeIcon icon={faBroom} className="" />
                        <span className="ml-2">Support</span>
                        </a>
                    </li>
                    </ul>
                    
                )}
                <li className="px-4 py-2 ">
                    <Link to="" className='flex items-center'>
                    <CalendarIcon className="w-5 h-5 menu-icon mr-2" />
                    <span className="menu-title">Surgery Schedule</span>
                    </Link>
                </li>
                <li className="px-4 py-2 ">
                    <Link to="" className='flex items-center'>
                    <CogIcon    className="w-5 h-5 menu-icon mr-2" />
                    <span className="menu-title">Settings</span>
                    </Link>
                </li>
                <li className="px-4 py-2 block md:hidden">
                    <Link onClick={(e) => { e.preventDefault(); logout(); }} className='flex items-center' >
                        <ArrowRightOnRectangleIcon   className="w-5 h-5 mr-2" />Logout
                    </Link>
                </li>

                
            </ul>
           

            {/* Main Content with Sidebar */}

            <div className="lg:flex">
                {/* Sidebar (on larger screens) */}
                <Sidebar/>
                <Dashboard/>
            </div>

            {/* Footer */}
            
        </div>
  )
}
