import React from 'react';
import DashboardHeader from './DashboardHeader';
import StatsCard from './StatsCard';
import Linechart from './Linechart';
import DoughnutChart from './DoughnutChart ';
import RecentTickets from './RecentTickets';
import ProjectStatus from './ProjectStatus';
import Footer from './Footer';
import Profit from './Profit';
import SurgeryCalendar from './SurgeryCalendar';
import Requestsur from './Requestsur';

const Dashboard = () => {

  const surgeryData = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      {
        label: 'Appendectomy',
        data: [12, 15, 10, 18, 20],
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1,
      },
      {
        label: 'Hip Replacement',
        data: [5, 6, 8, 7, 9],
        fill: false,
        borderColor: 'rgb(255, 159, 64)',
        tension: 0.1,
      },
      {
        label: 'Knee Replacement',
        data: [3, 4, 5, 6, 7],
        fill: false,
        borderColor: 'rgb(153, 102, 255)',
        tension: 0.1,
      },
    ],
  };


  const patientTrafficData = {
    labels: ['Direct Walk-ins', 'Doctor Referrals', 'Emergency Visits', 'Scheduled Appointments'],
    datasets: [
      {
        data: [120, 150, 80, 200],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
      },
    ],
  };

  return (
    <div className="min-h-screen bg-gray-100 w-full">
      <div className="p-3 md:p-6">
        <DashboardHeader />

        {/* Stats Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mt-3  md:mt-6">
          <StatsCard
            title="Surgeries"
            amount="15,0000"
            status="Increased by 60%"
            bgColor="bg-gradient-to-r from-red-500 to-pink-500"
            icon="mdi-chart-line"
          />
          <StatsCard
            title="Patients"
            amount="45,6334"
            status="Decreased by 10%"
            bgColor="bg-gradient-to-r from-blue-500 to-teal-500"
            icon="mdi-bookmark-outline"
          />
          <StatsCard
            title="Lab Tests"
            amount="95,5741"
            status="Increased by 5%"
            bgColor="bg-gradient-to-r from-green-500 to-teal-400"
            icon="mdi-diamond"
          />
        </div>

        {/* Charts Section */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 md:gap-6 mt-3  md:mt-6">
          <div className="">
          <Linechart data={surgeryData} />
          <Profit/>

          </div>
          <DoughnutChart data={patientTrafficData} />
        </div>

        {/* Recent Tickets */}
        <div className="mt-3  md:mt-6">
          <RecentTickets />
        </div>

       

        {/* Project Status */}
        <div className="mt-3  md:mt-6 ">
          <ProjectStatus />
        </div>
        <div className="flex mt-3 justify-center  md:mt-6 gap-6">
          <SurgeryCalendar />
          <Requestsur/>
        </div>

       
      </div>

      <Footer />
    </div>
  );
};

export default Dashboard;
