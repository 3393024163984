import React from 'react'
import { Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

// Register necessary components for Doughnut chart
ChartJS.register(ArcElement, Tooltip, Legend);

export default function DoughnutChart ({ data }) {
  return (
    <div className="w-full bg-white p-4 shadow-md rounded-md">
      <h4 className="text-xl font-semibold mb-4">Traffic Sources</h4>
      <Doughnut data={data} />
    </div>
  )
}
