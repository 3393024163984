import React from 'react'

import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function Linechart({data}) {
  return (
    <div className="w-full bg-white p-4 shadow-md rounded-md">
      <h4 className="text-xl font-semibold mb-4">Surgical Performance Overview</h4>
      <Line data={data} />
    </div>
  )
}
