import React from 'react'

import equipment from '../images/equipment.jpg'
import doctor from '../images/doctors.jpg'
import service from '../images/service.jpg'
export default function WeDo() {

    const services = [
        {
            id: 1,
            image: equipment,
            title: "Advanced Equipment",
            description: "Our state-of-the-art medical equipment ensures accurate diagnoses and effective treatments.",
            buttonText: "Learn More",
            
        },
        {
            id: 2,
            image: doctor,
            title: "Expert Doctors",
            description: "Our team of experienced doctors is committed to providing exceptional care and support.",
            buttonText: "Meet Our Team",
            
        },
        {
            id: 3,
            image: service,
            title: "Comprehensive Services",
            description: "We offer a wide range of healthcare services tailored to meet your needs.",
            buttonText: "Explore Services",
            
        },
    ];

    return (
        <div className='p-6 md:p-14 bg-gray-50 rounded-lg '>
      <div className="text-center">
        <h2 className='text-4xl font-bold'>What We Offer</h2>
        <p className='text-lg text-gray-700'>Discover the advanced medical services and dedicated professionals at HopeMed.</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-8">
        {services.map((item) => {
          return (
            <div className="max-w-full rounded overflow-hidden shadow-lg">
            <img className="w-full" src={item.image} alt="Sunset in the mountains" />
            <div className="px-6 py-6">
              <div className="font-bold text-xl mb-4">{item.title}</div>
              <p className="text-gray-700 text-base mb-6">{item.description}</p>
              <div className="flex justify-center">

                <button class="bg-teal-500 hover:bg-teal-400 text-white font-bold py-2 px-4 border-b-4 border-teal-700 hover:border-teal-500 rounded">
                  {item.buttonText}
                </button>
              </div>
            </div>
            
          </div>
          
          );
        })}
      </div>
    </div>
    

  )
}
