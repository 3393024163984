import React from 'react'

import about from '../images/about.jpg'
export default function About() {
  return (
    <div className='p-6 md:p-14'>
  <div className="flex flex-col  lg:flex-row items-center  space-y-6 md:space-y-0 md:space-x-8">
    <div className="left flex justify-center  p-4 flex-1 md:max-w-md">
      <img src={about} alt="About Us" className="rounded-lg shadow-lg w-full h-auto" />
    </div>
    <div className="right px-4 md:py-8 flex-1 space-y-4">
      <p className='text-xl text-gray-500'>About Us</p>
      <h1 className='text-3xl font-bold'>Commitment to Care</h1>
      <p className='text-gray-700'>
        At HopeMed, we are dedicated to providing exceptional, patient-centered healthcare. Our skilled team works tirelessly to ensure every individual receives compassionate treatment and support, fostering a healing environment for all.
      </p>

      <div>
        <div className="flex items-start space-x-4 mt-6">
          <div className="flex-shrink-0">
            <svg className="h-6 w-6 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </div>
          <div>
            <h2 className='text-xl font-semibold'>Comprehensive Care</h2>
            <p className='text-gray-600'>We offer a wide range of services to meet the diverse needs of our patients, from routine check-ups to specialized treatments.</p>
          </div>
        </div>

        <div className="flex items-start space-x-4 mt-4">
          <div className="flex-shrink-0">
            <svg className="h-6 w-6 text-blue-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
            </svg>
          </div>
          <div>
            <h2 className='text-xl font-semibold'>Community Focused</h2>
            <p className='text-gray-600'>We are actively involved in our community, offering health education and free screenings to promote overall wellness.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>





  )
}
