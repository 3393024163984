import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';

import Home from './components/screens/Home'
import Signin from './components/screens/Signin'
import Signup from './components/screens/Signup'
import Admin from './components/screens/admin/Admin'


function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={ <Home/> }></Route>
          <Route path="/signin" element={ <Signin/> }></Route>
          <Route path="/signup" element={ <Signup/> }></Route>
          <Route path="/dashboard" element={ <Admin/> }></Route>
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
