import React,{useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { ChevronDownIcon,ArrowRightOnRectangleIcon    } from '@heroicons/react/24/solid'; 
import { BellIcon,HomeIcon,Bars3Icon,XMarkIcon   } from '@heroicons/react/24/outline'; 
import { UserIcon,UserGroupIcon ,ChartBarIcon, CalendarIcon, CogIcon ,BeakerIcon   } from '@heroicons/react/24/solid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd,  faUserTie, faBroom } from '@fortawesome/free-solid-svg-icons';
import Nurse from '../../images/icons/nurse.png'
import Patient from '../../images/icons/patient.png'


export default function Sidebar() {
  const navigate=useNavigate();
  const [isUiBasicOpen, setIsUiBasicOpen] = useState(false);
  const [isIconsOpen, setIsIconsOpen] = useState(false);
  const [isFormsOpen, setIsFormsOpen] = useState(false);
  const [isChartsOpen, setIsChartsOpen] = useState(false);
  const [isTablesOpen, setIsTablesOpen] = useState(false);

  const toggleCollapse = (setStateFn) => {
    setStateFn(prevState => !prevState);
  };
  const logout = () => {
    navigate('/');
};
  return (
    <nav className="hidden lg:block sidebar-offcanvas w-64 bg-gray-800 text-white  p-4">
      <ul className="nav space-y-4">
        {/* Profile Section */}
        <li className="nav-item nav-profile mb-8">
          <a href="#" className="nav-link flex items-center">
            <div className="nav-profile-image relative">
              <img src={require('../../images/male/face1.jpg')} alt="profile" className="w-12 h-12 rounded-full" />
              <span className="login-status online absolute bottom-0 right-0 w-3 h-3 bg-green-500 border-2 border-white rounded-full"></span>
            </div>
            <div className="nav-profile-text flex flex-col ml-3">
              <span className="font-bold mb-1">Sandy kery</span>
              <span className="text-sm text-gray-400">Admin</span>
            </div>
            
          </a>
        </li>

        {/* Dashboard */}
        <li className="nav-item">
          <button
            onClick={() => toggleCollapse(setIsIconsOpen)}
            className="nav-link flex items-center w-full text-left"
          >
            <HomeIcon className="h-6 w-6  menu-icon " />
            <span className="menu-title ml-2">Dashboard</span>
          </button>
          
        </li>

        {/* Analytics */}
        <li className="nav-item">
          <button
            onClick={() => toggleCollapse(setIsIconsOpen)}
            className="nav-link flex items-center w-full text-left"
          >
            <ChartBarIcon className="w-5 h-5 menu-icon mr-2" />
            <span className="menu-title">Analytics</span>
            
            
          </button>
          
        </li>

        {/* Lab Reports */}
        <li className="nav-item">
          <button
            onClick={() => toggleCollapse(setIsIconsOpen)}
            className="nav-link flex items-center w-full text-left"
          >
            <BeakerIcon className="w-5 h-5 menu-icon mr-2" />
            <span className="menu-title">Lab Reports</span>
          </button>
         
        </li>

        {/* Patients */}
        <li className="nav-item">
          <button
            onClick={() => toggleCollapse(setIsFormsOpen)}
            className="nav-link flex items-center w-full text-left"
          >
            <img src={Patient} alt="" className='w-5 menu-icon mr-2' />
            <span className="menu-title">Patients</span>
          </button>
       
        </li>

        {/* Staffs */}
        <li className="nav-item">
          <button
            onClick={() => toggleCollapse(setIsChartsOpen)}
            className="nav-link flex items-center w-full text-left"
          >
            <UserGroupIcon className="w-5 h-5 menu-icon mr-2" />
            <span className="menu-title">Staffs</span>
            <ChevronDownIcon className={`w-5 h-5 ml-auto ${isUiBasicOpen ? 'transform rotate-180' : ''}`} />
            
          </button>
          {isChartsOpen && (
            <ul className="nav flex-col m-4 space-y-2">
              
              <li className="nav-item">
                <a className="nav-link" href="">
                  <FontAwesomeIcon icon={faUserMd} className="" />
                  <span className="ml-2">Doctor</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link flex" href="">
                  <img src={Nurse} alt="" className='w-4' />
                  <span className="ml-2">Nurse</span>
                </a>
              </li>
             
              <li className="nav-item">
                <a className="nav-link" href="">
                  <FontAwesomeIcon icon={faUserTie} className="" />
                  <span className="ml-2">Manager</span>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="">
                  <FontAwesomeIcon icon={faBroom} className="" />
                  <span className="ml-2">Support</span>
                </a>
              </li>
            </ul>
            
          )}
        </li>

        {/* Schedule */}
        <li className="nav-item">
          <button
            onClick={() => toggleCollapse(setIsTablesOpen)}
            className="nav-link flex items-center w-full text-left"
          >
            <CalendarIcon className="w-5 h-5 menu-icon mr-2" />
            <span className="menu-title">Surgery Schedule</span>
          </button>
       
        </li>

        {/* Settings */}
        <li className="nav-item">
          <button
            className="nav-link flex items-center w-full text-left"
          >
            <CogIcon    className="w-5 h-5 menu-icon mr-2" />
            <span className="menu-title">Settings</span>
          </button>
          
        </li>

        {/* Logout */}
        <li className="nav-item ">
          <a className="nav-link flex items-center" href="" onClick={logout}>
            <ArrowRightOnRectangleIcon    className="w-5 h-5 menu-icon mr-2" />
            <span className="menu-title">Logout</span>
          </a>
        </li>
      </ul>
    </nav>
  )
}
