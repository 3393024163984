import React, { useEffect, useState } from 'react';

import BrainIcon  from '../images/icons/brainstorm.png'
import HeartIcon  from '../images/icons/heart.png'
import StomachIcon  from '../images/icons/stomach.png'
import EyeIcon  from '../images/icons/eye.png'
import KneeIcon  from '../images/icons/knee.png'
import GallbladderIcon  from '../images/icons/gallbladder.png'
import BearIcon  from '../images/icons/bear.png'

import doc1 from '../images/doctors/doc1.jpg'
import doc2 from '../images/doctors/doc2.jpg'
import doc3 from '../images/doctors/doc3.jpg'
import doc4 from '../images/doctors/doc4.jpg'
import doc5 from '../images/doctors/doc5.jpg'
import doc6 from '../images/doctors/doc6.jpg'
import doc7 from '../images/doctors/doc7.jpg'


const images = [
  { 
    src: doc1, 
    type: 'Neurosurgery', 
    surgeon: ['Dr. Aaron', 'Dr. Rodriguez', 'Dr. Wilson'], 
    icon: <img src={BrainIcon} alt="Stomach Icon" className="h-6 w-6" />,
    subtext: 'Involves surgical treatment for conditions affecting the brain, spine, and nervous system.' ,
    pcolor : 'bg-pink-500'
},

  { 
      src: doc2, 
      type: 'Cardiology', 
      surgeon: ['Dr. Marcus', 'Dr. Brown', 'Dr. White'] , 
      icon: <img src={HeartIcon} alt="Stomach Icon" className="h-6 w-6" />,
      subtext: 'Specializes in diagnosing and treating heart and vascular diseases.',
      pcolor : 'bg-red-500'
  },
  { 
      src: doc3, 
      type: 'Orthopedics', 
      surgeon: ['Dr. Melendez', 'Dr. Lee', 'Dr. Kim'] , 
      icon: <img src={KneeIcon} alt="Stomach Icon" className="h-6 w-6" />,
      subtext: ' Focuses on the musculoskeletal system, including bones, joints, and muscles.',
      pcolor : 'bg-orange-200'
  },
  { 
      src: doc4, 
      type: 'Gastroenterology', 
      surgeon: ['Dr. Shaun', 'Dr. Miller', 'Dr. Garcia'] , 
      icon: <img src={StomachIcon} alt="Stomach Icon" className="h-6 w-6" />,
      subtext: 'Deals with diseases and disorders of the digestive system, including the stomach and intestines.',
      pcolor : 'bg-red-400'
  },
  
  { 
      src: doc5, 
      type: 'Ophthalmology', 
      surgeon: ['Dr. Claire', 'Dr. Martinez', 'Dr. Young'],
      icon: <img src={EyeIcon} alt="Stomach Icon" className="h-6 w-6" />, 
      subtext: 'Concerned with the diagnosis and treatment of eye disorders and visual system health.',
      pcolor : 'bg-black'
  },
  { 
      src: doc6, 
      type: 'Urology', 
      surgeon:  ['Dr. Hernandez', 'Dr. Clark', 'Dr. Lewis'] , 
      icon: <img src={GallbladderIcon} alt="Stomach Icon" className="h-6 w-6" />,
      subtext: 'Specializes in the diagnosis and treatment of urinary tract and male reproductive system disorders.',
      pcolor : 'bg-green-500'
  },
  { 
      src: doc7, 
      type: 'Pediatrics', 
      surgeon:  ['Dr. Morgan', 'Dr. Clark', 'Dr. Lewis'] , 
      icon: <img src={BearIcon} alt="Stomach Icon" className="h-6 w-6" />,
      subtext: 'Focuses on the medical care of infants, children, and adolescents.',
      pcolor : 'bg-amber-600'
  },
];

export default function Team() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        }, 3000); // Change image every 3 seconds

        return () => clearInterval(interval);
    }, []);
    return (
      <div className='p-6 md:p-14'>
      <h2 className='text-4xl font-bold text-center mb-8'>Meet Our Team</h2>
      <div className="flex flex-col md:flex-row">
  <div className="md:w-1/2  flex justify-center">
    <div  key={currentIndex}  className="bg-white shadow-lg rounded-lg overflow-hidden ">
      <img 
        
        src={images[currentIndex].src} 
        alt="image"
        className=" w-64 h-96 object-cover rounded-sm " 
      />
      <div className="p-4">
        <h2 className="text-lg font-bold text-center">{images[currentIndex].surgeon[0]}</h2>
      </div>
    </div>
  </div>
  <div className="md:w-1/2 flex flex-col justify-center p-4">
    <div className="flex flex-col mt-4">
    {images.map((image, index) => (
      <div
        key={index}
        className={`flex ${currentIndex === index ? 'slide-up' : ''} ${currentIndex + 1 === index ? 'fade-in' : ''}`}
      >
        {currentIndex === index ? (
          <div className="flex text-black gap-4 md:py-6 scale-up">
            <div className="h-6 w-6 p-0 hidden md:flex">
            {image.icon}
            </div>
              
            <div className="space-y-2">
              <p className="text-xl text-center md:text-left">{image.type}</p>
              <p className="text-sm text-center md:text-left">{image.subtext}</p>
              <div className="gap-2 flex justify-center justify-left">
                {image.surgeon.map((item, idx) => (
                  <p key={idx} className="bg-gray-300 rounded-lg p-1">{item}</p>
                ))}
              </div>
            </div>
          </div>
        ) : currentIndex + 1 === index ? (
          <div className="hidden md:flex items-center text-gray-600 gap-4 py-2 fade-in">
            <div className={`w-4 h-4 p-1 ${image.pcolor} rounded-full`}></div>
            <p className='text-center md:text-left'>{image.type}</p>
          </div>
        ) : (
          <div className="hidden md:flex items-center text-gray-300 gap-4 py-2">
            <div className="w-3 h-3 mr-1 bg-gray-300 rounded-full"></div>
            <p className='text-center md:text-left'>{image.type}</p>
          </div>
        )}
      </div>
    ))}
     </div>
  </div>
</div>
    </div>

    
    )
}
