import React from 'react'
import blood from '../images/blooddonation.jpg'
import eye from '../images/eyetest.jpg'
import checkup from '../images/HealthCheck.jpg'
import vaccine from '../images/vaccination.webp'
import therapy from '../images/therapy.jpg'
import cancer from '../images/cancer.jpg'


export default function Campaigns() {
    const socialServices = [
        {
          title: "Blood Donation Drives",
          description:
            "Join our life-saving mission! We regularly organize blood donation camps to ensure a steady supply of blood for patients in need. Your contribution can save lives.",
          imageUrl: blood,
          cta: "Find a Drive",
        },
        {
          title: "Free Eye Screening",
          description:
            "Our free eye testing programs aim to help identify vision problems early. We offer free screenings and consultations for all age groups to promote better vision.",
          imageUrl: eye,
          cta: "Get Tested",
        },
        {
          title: "Complete  Health Screenings",
          description:
            "Our health screening camps offer a range of free or low-cost tests such as blood pressure, diabetes, cholesterol, and more, helping you take control of your health.",
          imageUrl: checkup,
          cta: "Upcoming Camps",
        },
        {
          title: "Vaccination Drives",
          description:
            "Stay protected against preventable diseases with our free vaccination programs. We organize regular vaccination drives in the community to ensure everyone has access to vital immunizations.",
          imageUrl: vaccine,
          cta: "Find a Camp",
        },
        {
          title: "Mental Support Programs",
          description:
            "We are committed to raising awareness about mental health and offering support. Our programs include free counseling services and mental health workshops to help with stress, anxiety, and depression.",
          imageUrl: therapy,
          cta: "Get Support",
        },
        {
          title: "Cancer Awareness Campaign",
          description:
            "Early detection saves lives. Join us in raising awareness and providing free screenings for various types of cancer, including breast, prostate, and cervical cancers.",
          imageUrl: cancer,
          cta: "Schedule Now",
        },
        
      ];
      
    return (
      <div className="p-6 md:p-14 bg-gray-50">
      <h2 className="text-3xl font-bold text-center mb-2">
      Community Support Services
      </h2>
      <p className="text-lg text-center mb-12">
        Serving Beyond Healthcare – Making a Difference in Our Community
      </p>
      <div className=" grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-8">
        {socialServices.map((service, index) => {
          return (
            <div key={index} className="max-w-full rounded overflow-hidden shadow-lg">
              <img className="w-full md:h-80" src={service.imageUrl} alt="Sunset in the mountains" />
              <div className="px-6 py-6">
                <div className="font-bold text-xl mb-4">{service.title}</div>
                <p className="text-gray-700 text-base mb-6">{service.description}</p>
                <div className="flex justify-center">

                  <button class="bg-teal-500 hover:bg-teal-400 text-white font-bold py-2 px-4 border-b-4 border-teal-700 hover:border-teal-500 rounded">
                    {service.cta}
                  </button>
                </div>
              </div>
              
            </div>
          
          );
        })}
      </div>
     
    </div>
    
    )
}
