import React from 'react';
import { patientDetails } from '../../data/data';

const RecentTickets = () => {
  return (
    <div className="bg-white p-6 shadow-lg rounded-lg">
      <h4 className="text-xl font-semibold mb-4">Recent Patients</h4>
      <div className="overflow-x-auto"> {/* Makes table horizontally scrollable on small screens */}
        <table className="table-auto w-full sm:table-auto">
          <thead>
            <tr className="text-left text-gray-700">
              <th className="p-2">Image</th>
              <th className="p-2">Patient</th>
              <th className="p-2">Disease</th>
              <th className="p-2">Status</th>
              <th className="p-2">Last Update</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(patientDetails)
              .slice(0, 5)
              .reverse()
              .map(([id, item]) => (
                <tr key={id} className="border-t">
                  <td className="p-2">{item.image}</td>
                  <td className="p-2">{item.name}</td>
                  <td className="p-2">{item.surgery}</td>
                  <td className="p-2">
                    <span
                      className={`badge uppercase 
                      ${item.status === 'Pending' ? 'bg-yellow-500' :
                        item.status === 'Scheduled' ? 'bg-blue-500' :
                        item.status === 'Discharged' ? 'bg-green-500' :
                        item.status === 'Transferred' ? 'bg-orange-500' :
                        item.status === 'Admitted' ? 'bg-red-500' : 'bg-gray-500'}
                      text-white p-1 rounded-md`}
                    >
                      {item.status}
                    </span>
                  </td>
                  <td className="p-2">{item.date}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentTickets;
