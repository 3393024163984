import React from 'react';
const DashboardHeader = () => {
  return (
    <div className="flex justify-between items-center py-4">
      <h3 className="text-2xl font-bold text-gray-800">
        <span className="bg-gradient-to-r from-blue-500 to-teal-500 text-black p-2 rounded-full mr-2">
       
        </span>
        Dashboard
      </h3>
      <p className="hidden sm:block">Overview</p>
    </div>
  );
};

export default DashboardHeader;
