import React from 'react'
import { CalendarIcon} from '@heroicons/react/24/outline';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd } from '@fortawesome/free-solid-svg-icons';
export default function Requestsur() {
    
    const surgeryRequests = [
        {
        id: 1,
        doctor: 'Dr. Smith',
        patient: 'John Doe',
        surgeryType: 'Appendectomy',
        requestedDate: '2024-11-20',
        status: 'pending',
        },
        {
        id: 2,
        doctor: 'Dr. Johnson',
        patient: 'Jane Roe',
        surgeryType: 'Knee Replacement',
        requestedDate: '2024-11-22',
        status: 'pending',
        },
        {
        id: 3,
        doctor: 'Dr. Lee',
        patient: 'Michael Brown',
        surgeryType: 'Heart Bypass',
        requestedDate: '2024-11-23',
        status: 'pending',
        },
        
    ];
    return (
        <div className="hidden md:block flex-1 space-y-2">
            {surgeryRequests.map((surgery)=>(

            <div className=" bg-white shadow-md rounded-lg p-4   border border-gray-200">

            <div className="flex items-center space-x-3">
                {/* Patient's Initial */}
                <div className="w-10 h-10 flex items-center justify-center bg-blue-500 text-white font-semibold text-xl rounded-full">
                {surgery.patient.charAt(0).toUpperCase()}
                </div>
                <div className="flex-1">
                <p className="text-xl font-bold text-gray-800">{surgery.patient}</p>
                <p className="text-sm text-gray-600">{surgery.surgeryType}</p>
                </div>
            </div>

            <div className="flex justify-between flex-wrap items-center m-4">
                <div className="flex items-center gap-2">
                    <CalendarIcon className='h-4 w-4'/>
                    <p className="text-sm text-gray-600">{surgery.requestedDate}</p>
                </div>
                <div className="flex items-center gap-2">
                    <FontAwesomeIcon icon={faUserMd} className="h-3 w-3" />
                    <p className="text-sm text-gray-700 font-medium">{surgery.doctor}</p>
                </div>
            </div>


            <div className="mt-4 flex space-x-3">
                <button className=" py-2 px-4">
                Reject
                </button>
                <button className="w-full py-2 px-4  bg-green-500 rounded-lg hover:bg-green-600">
                Accept
                </button>
            </div>
            </div>
            ))}
            
        </div>

    )
}

