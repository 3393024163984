import React from 'react'
import {FaMoneyBill } from 'react-icons/fa';

export default function Profit() {
    const profit = 50000; // Example profit value
  const income = 70000; // Example income value
  const expense = 30000; // Example expense value
  
  return (
    <div className="flex-grow shadow-md rounded-md bg-white p-4 mt-2 ">
        <div className="md:p-6">
         
        
          <div className="flex items-center mb-2 ">
            <FaMoneyBill className="mr-2 text-xl text-green-500" /> 
            <h2 className="text-xl font-semibold text-left ">Financial Flow</h2>
          </div>
        
            <div className="text-left mb-4">
                <span className="text-2xl font-bold text-green-600">${profit}</span>
            </div>
            <div className="mb-4">
        <div className="flex items-center mb-2">
          <span className="text-gray-700 mr-2">Income</span>
          <span className="text-sm text-gray-500">${income}</span>
        </div>
        <div className="h-8 bg-green-200 rounded-xl">
          <div
            className="h-full bg-green-500 rounded-xl"
            style={{ width: `${(income / (income + expense)) * 100}%` }}
          ></div>
        </div>
      </div>
      <div>
        <div className="flex items-center mb-2">
          <span className="text-gray-700 mr-2">Expense</span>
          <span className="text-sm text-gray-500">${expense}</span>
        </div>
        <div className="h-8 bg-red-200 rounded-xl">
          <div
            className="h-full bg-red-500 rounded-xl"
            style={{ width: `${(expense / (income + expense)) * 100}%` }}
          ></div>
        </div>
      </div>

        </div>
    </div>
  )
}
