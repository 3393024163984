import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import logo from '../../components/images/icons/logo.png';
import logosm from '../../components/images/icons/logosm.png';

export default function Navbar({page}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className='shadow-lg z-50'>
      <div className='fixed shadow-lg p-4 flex items-center justify-between w-full transition-colors duration-300 bg-white text-black'>
        <img src={logo} alt="Logo" width={120} className='hidden md:block' />
        <img src={logosm} alt="Logo" width={60} className='md:hidden' />

        <button onClick={toggleMenu} className='md:hidden text-xl'>
          {isOpen ? '✖' : '☰'}
        </button>

        <ul className={`flex p-4 md:p-0 md:flex-row flex-col gap-4 md:static absolute md:bg-transparent bg-white transition-transform duration-300 ${isOpen ? 'top-12 left-0 w-full' : 'hidden md:flex'}`}>
          <li className='hover:text-secondary hover:border-b-2 hover:border-secondary'>
            <Link to="/" className={`text-xl  ${ page==='home' && 'border-b-2 text-primary border-primary'}`}>Home</Link>
          </li>
          <li className='hover:text-secondary hover:border-b-2 hover:border-secondary'>
            <Link to="/dashboard" className={`text-xl  `}>Admin</Link>
          </li>
          <li className='hover:text-secondary hover:border-b-2 hover:border-secondary'>
            <Link to="/signin" className={`text-xl  ${ page==='signin' && 'border-b-2 text-primary border-primary'}`}>Signin</Link>
          </li>
          <li className='hover:text-secondary hover:border-b-2 hover:border-secondary'>
            <Link to="/signup" className={`text-xl  ${ page==='signup' && 'border-b-2 text-primary border-primary'}`}>Signup</Link>
          </li>
        </ul>
      </div>
      <div className="p-8 w-full "></div>
    </div>
  );
}
