import React from 'react'

import hero from '../images/hero1.jpg'
export default function Hero() {
  return (
    <div 
      className="pt-20 bg-cover bg-center md:h-screen flex items-center " 
      style={{ backgroundImage: `url(${hero})` }}
    >
      <div className="w-full max-w-2xl p-6 md:p-12 lg:p-24 space-y-6 ">
        <h1 className="text-2xl md:text-4xl lg:text-5xl font-bold leading-tight ">
          Transforming Lives with Great Healthcare
        </h1>
        <p className="text-sm md:text-base lg:text-lg ">
          Your Trusted Partner for Comprehensive Surgical Care, Committed to Delivering Exceptional Outcomes and Personalized Support Throughout Your Surgical Journey.
        </p>
        <div className="flex ">
          
          <button class="bg-teal-500 hover:bg-teal-400 text-white font-bold py-2 px-4 border-b-4 border-teal-700 hover:border-teal-500 rounded">
          Book Now</button>
        </div>
      </div>
    </div>
  );
}
